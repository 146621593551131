import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "../../css/dashboard.css";
import { ReactComponent as ForestNameLogo } from "../../assets/SVG/forest-name.svg";
import DashboardComparisonMap from "./DashboardComparisonMap";
import Valiancelogo from "../../assets/Images/logo 1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { makeRequest2 } from "../../api";
import TadobaContext from "../Context/TadobaContext";
import filterIcon from "../../assets/SVG/filter.svg";
import Filter from "../../components/Dashboard/Filter";
import FloatingButton from "../FloatingButton";
import "../../css/FloatingButton.css";
import Dashboard2 from "./Dashboard2";
import Timer from "./Timer";
import DashboardContext from "../Context/DashboardContext";
import { ReactComponent as BackButton } from "../../assets/SVG/BackButton.svg";

dayjs.extend(customParseFormat);

const Dashboard = () => {
  const Dashboardapi = useContext(DashboardContext);
  const api = useContext(TadobaContext);
  const [causes, setCauses] = useState([]);
  const [selectedCause, setSelectedCause] = useState("");
  const [fromDate, setFromDate] = useState(api.fromDate);
  const [toDate, setToDate] = useState(api.toDate);
  const [selectedRange, setSelectedRange] = useState(api.range);
  const [forestName, setForestName] = useState("");
  const [mapCoords, setMapCoords] = useState([
    { lat: 21.572004, lng: 79.19164 },
  ]);
  const [mapAllCoords, setMapAllCoords] = useState([]);
  const [filteroption, setfilteroption] = useState(6);
  const [filterbutton, setfilterbutton] = useState(true);
  const [apiResponse, setapiResponse] = useState([]);
  const [apiAllResponse, setapiAllResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [userDetails, setUserDetails] = useState([]);

  // const [type, setType] = useState(Dashboardapi.type);

  const { type, setType } = useContext(DashboardContext);

  const handleFilterClick = () => setfilterbutton(!filterbutton);

  const navigate = useNavigate();

  const Logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.access_key}`,
        "Content-Type": "application/json",
      },
    };

    const jsondata = {
      refresh: localStorage.refresh,
    };
    try {
      const result = await makeRequest2(
        "post",
        `/user_profile/logout/`,
        jsondata,
        config
      );
      localStorage.clear();
      navigate("/");
      window.location.reload();
    } catch (error) {
      // console.log("json", jsondata);
      // console.log("config", config);
    }
  };

  // console.log(api.range);

  const handleLogout = () => {
    Logout();
  };

  const fetchCausesList = async (causeType) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL}/deforestation/causes-list/?cause_type=${causeType}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
          },
        }
      );

      setCauses(response.data.data);
    } catch (error) {
      console.log("error in fetching causes list", error);
    }
  };

  const userProfile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BE_URL}/user_profile/profile/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
          },
        }
      );

      setUserDetails(response.data);
    } catch (error) {
      console.log("error in fetching causes list", error);
    }
  };

  let cause_type = 2;
  const fetchMapData = async () => {
    if (type === "Deforestation") {
      cause_type = 1;
    }
    try {
      let fromdate = fromDate.replaceAll("/", "-");
      let todate = toDate.replaceAll("/", "-");

      if (new Date(fromdate) > new Date(todate)) {
        // Interchange dates if from date is greater than to date
        [fromdate, todate] = [todate, fromdate];
      }

      const requestOptions = {
        params: {
          from_date: fromdate,
          to_date: todate,
          cause_name: selectedCause,
          cause_type: cause_type,
          ...(forestName && { forest_name: forestName }),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_key")}`,
        },
      };

      const requestOptions1 = {
        params: {
          from_date: fromdate,
          to_date: todate,
          cause_name: "",
          cause_type: cause_type,
          ...(forestName && { forest_name: forestName }),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_key")}`,
        },
      };

      if (fromDate && toDate) {
        // console.log(fromDate, toDate);
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/deforestation/area-analysis-map/`,
          requestOptions
        );
        const response1 = await axios.get(
          `${process.env.REACT_APP_BE_URL}/deforestation/area-analysis-map/`,
          requestOptions1
        );

        // console.log("forest data are", response);
        // console.log("forest data are...", response.data.data);
        const coords = response.data.data.map((coord) => ({
          lat: parseFloat(coord.latitude),
          lng: parseFloat(coord.longitude),
          cause: coord.cause_name,
          date: coord.created_at,
        }));
        setMapCoords(coords);
        setapiResponse(response.data.data);
        setapiAllResponse(response1.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching map data:", error);
    }
  };

  // console.log(fromDate, toDate);

  useEffect(() => {
   
    userProfile();
    if (Dashboardapi.type === "Deforestation") {
      fetchCausesList(1);
    } else {
      fetchCausesList(2);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchMapData();
  }, [selectedCause, fromDate, toDate, forestName]);

  const handleCauseChange = (value) => {
    setSelectedCause(value);
  };

  const location = useLocation();

  const mapStyles = {
    width: "100%",
    height: "70vh",
  };

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const subtractMonths = (date, months) => {
    const newDate = new Date(date);

    const totalMonths =
      newDate.getFullYear() * 12 + newDate.getMonth() - months;

    const newYear = Math.floor(totalMonths / 12);
    const newMonth = totalMonths % 12;

    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);

    return newDate;
  };

  const handleToDateChange = (value) => {
    let rangeDate;
    if (value) {
      let regex = /\b\d{1,2}\b/g;
      let matches = value.match(regex);
      rangeDate = getFormattedDate(
        subtractMonths(toDate, parseInt(matches[0], 10))
      );
      // rangeDate = format(calculatedDate, 'yyyy-MM-dd');
    }
  };

  const handleBack = () => {
    navigate("/section");
  };

  return (
    <div style={{ width: "100%" }}>
      <header className="header">
        {/* header left */}
        <div className="header_left">
          <div className="header_left_div1">
            <img
              className="forest_img"
              src={require("../../assets/Images/pench_logo.jpeg")}
            />
            <div className="Application-name">
              <p>
                {Dashboardapi.type === "Deforestation"
                  ? "Deforestation Monitoring"
                  : "Emission Monitoring"}
              </p>

              <ul>
                <li>
                  <span>Powered By</span>
                </li>
                <li>
                  <img src={Valiancelogo}></img>
                </li>
              </ul>
            </div>
          </div>
          <div className="header_left_div2">
            <h3>Hi {userDetails?.first_name}!</h3>

            <div style={{ position: "relative", top: "-1.6rem", left: "4rem" }}>
              <BackButton
                style={{ position: "relative", left: "2.2rem", top: "3px" }}
              />
              <button
                onClick={handleBack}
                style={{
                  background: "aliceblue",
                  padding: "6px 26px",
                  borderRadius: "6px",
                  fontWeight: "600",
                  marginLeft: "1rem",
                  marginTop: "3px",
                  border: "0px solid blue",
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.31) 0px 1px 10px",
                }}
              >
                Home
              </button>
            </div>
            <p>
              {Dashboardapi.type === "Deforestation"
                ? "Protect Forests , Save Lives"
                : "Protect Environment , Save Lives"}
            </p>
          </div>
        </div>
        <div className="header_right">
          <ul>
            <li className="forest-Name">
              <div>
                <ForestNameLogo style={{ width: "4vh", height: "3vh" }} />
              </div>
              <div>
                <h4>Pench Tiger Reserve</h4>
              </div>
            </li>
            <hr style={{ height: "5vh", opacity: "0.6" }} />
            <Timer />
            <hr style={{ height: "5vh", opacity: "0.6" }} />

            <li className="filterIcon">
              <img
                src={filterIcon}
                alt="filterIcon"
                onClick={handleFilterClick}
              />
            </li>
            <hr style={{ height: "5vh", opacity: "0.6" }} />

            <li>
              <FloatingButton onLogout={handleLogout} />
            </li>
          </ul>
        </div>
      </header>

      {!filterbutton ? (
        <Filter
          setfilterbutton={setfilterbutton}
          Filterbutton={filterbutton}
          filteroption={filteroption}
          setfilteroption={setfilteroption}
        />
      ) : (
        ""
      )}
      {filteroption === 5 ? (
        <DashboardComparisonMap
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          selectedCause={selectedCause}
          mapCoords={mapCoords}
          isLoading={isLoading}
          imageUrl1={imageUrl1}
          imageUrl2={imageUrl2}
        />
      ) : (
        ""
      )}
      {filteroption === 6 ? (
        <Dashboard2
          mapCoords={mapCoords}
          setMapCoords={setMapCoords}
          apiAllResponse={apiAllResponse}
          isLoading={isLoading}
          apiResponse={apiResponse}
          setToDate={setToDate}
          setFromDate={setFromDate}
          setSelectedCause={setSelectedCause}
          setfilteroption={setfilteroption}
          fromDate={fromDate}
          toDate={toDate}
          causes={causes}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Dashboard;
