import React, { useEffect, useState } from "react";
import AreaAnalysisIcon from "../../../assets/SVG/areaAnalysisIcon.svg";
import CloseIcon from "../../../assets/SVG/close.svg";
import Iicon from "../../../assets/SVG/Iicon.svg";
import "../../../css/areaAnalysis.css";
import { makeRequest } from "../../../api";
import axios from "axios";
import ApexLineChart from "./LineChart.jsx";
import PieChart from "./PieChart.jsx";
import { Button, Modal, DatePicker, Space, Select, TimePicker } from "antd";
import dayjs from "dayjs";
const dateFormat = "YYYY-MM-DD";
// const dateFormat = "DD-MM-YYYY";
const displayFormat = "DD-MM-YYYY";
const internalFormat = "YYYY-MM-DD";
const { Option } = Select;

const AreaAnalysis = ({
  apiResponse,
  setToDate,
  setFromDate,
  setSelectedCause,
  name,
  chart,
  setfilteroption,
  fromDate,
  toDate,
}) => {
  // console.log("name of the chart", name);
  // console.log("type of chart", chart);

  const [forestName, setForestName] = useState("Pench Tiger Reseve");
  const [selectedRange, setSelectedRange] = useState("Past 3 months");

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const subtractMonths = (date, months) => {
    const newDate = new Date(date);

    const totalMonths =
      newDate.getFullYear() * 12 + newDate.getMonth() - months;

    const newYear = Math.floor(totalMonths / 12);
    const newMonth = totalMonths % 12;

    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);

    return newDate;
  };

  // const handleRangeChange = (value) => {
  //   const date = new Date();
  //   const presentDate = getFormattedDate(date);
  //   let rangeDate;
  //   if (value === "Past 3 months") {
  //     rangeDate = getFormattedDate(subtractMonths(date, 3))

  //   } else if (value === "Past 9 months") {
  //     rangeDate = getFormattedDate(subtractMonths(date, 9))
  //   } else if (value === "Past 1 year") {
  //     rangeDate = getFormattedDate(subtractMonths(date, 12))
  //   } else if (value === "Past 2 year") {
  //     rangeDate = getFormattedDate(subtractMonths(date, 24))
  //   } else if (value === "Past 3 year") {
  //     rangeDate = getFormattedDate(subtractMonths(date, 36))
  //   }
  //   setToDate(presentDate)
  //   setFromDate(rangeDate)
  // };

  const handleRangeChange = (value) => {
    const date = toDate;
    // console.log("date is", date);
    setSelectedRange(value);
    // const presentDate = getFormattedDate(date);
    let rangeDate;
    if (value === "Past 3 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 3));
    } else if (value === "Past 6 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 6));
    } else if (value === "Past 9 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 9));
    } else if (value === "Past 12 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 12));
    } else if (value === "Past 15 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 15));
    } else if (value === "Past 18 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 18));
    } else if (value === "Past 21 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 21));
    } else if (value === "Past 24 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 24));
    } else if (value === "Past 27 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 27));
    } else if (value === "Past 30 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 30));
    } else if (value === "Past 33 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 33));
    } else if (value === "Past 36 months") {
      rangeDate = getFormattedDate(subtractMonths(date, 36));
    }
    // setSelectedRange(value);
    // else if(value === "Past 9 months"){
    //   rangeDate= getFormattedDate(subtractMonths(date, 9))
    // }else if(value === "Past 1 year"){
    //   rangeDate= getFormattedDate(subtractMonths(date, 12))
    // }else if(value === "Past 2 year"){
    //   rangeDate= getFormattedDate(subtractMonths(date, 24))
    // }else if(value === "Past 3 year"){
    //   rangeDate= getFormattedDate(subtractMonths(date, 36))
    // }
    // setToDate(presentDate)
    // console.log("range date is", rangeDate);
    setFromDate(rangeDate);
    // setToDate(toDate)
    // console.log("fromdate in frequncymodal is", fromDate);
    console.log(toDate);
  };

  const ranges = [
    {
      value: "1",
      label: "Past 3 months",
    },
    {
      value: "2",
      label: "Past 6 months",
    },
    {
      value: "3",
      label: "Past 9 months",
    },
    {
      value: "4",
      label: "Past 12 months",
    },
    {
      value: "5",
      label: "Past 15 months",
    },
    {
      value: "6",
      label: "Past 18 months",
    },
    {
      value: "7",
      label: "Past 21 months",
    },
    {
      value: "8",
      label: "Past 24 months",
    },
    {
      value: "9",
      label: "Past 27 months",
    },
    {
      value: "10",
      label: "Past 30 months",
    },
    {
      value: "11",
      label: "Past 33 months",
    },
    {
      value: "12",
      label: "Past 36 months",
    },
  ];

  const handleclosebutton = () => {
    setfilteroption(null);
  };
  const handleDateChange = (date, dateString) => {
    const formattedDate = date ? dayjs(date).format(internalFormat) : "";
    setToDate(formattedDate);
  };

  return (
    <div className="AreaAnalysisComp">
      {/* <h1>Hi You are in Area analysis component</h1> */}
      <div className="area-analysis-header">
        <div className="area_header_left">
          <ul className="area_header_left_list">
            <li className="area_header_left_icon">
              <img src={AreaAnalysisIcon} alt="AreaAnalysisIcon" />
            </li>
            <li>
              <p className="area_header_left_text">{name} Analysis</p>
            </li>
          </ul>
        </div>
        <div className="area_header_right">
          <ul className="area_header_right_list">
            <li className="area_header_right_text"></li>
          </ul>
        </div>
      </div>
      <div className="area-analysis-chart">
        {chart === "linechart" ? (
          <ApexLineChart
            response={apiResponse}
            setSelectedCause={setSelectedCause}
          />
        ) : (
          <PieChart response={apiResponse} />
        )}
      </div>

      <div className="area-analysis-footer">
        <img src={Iicon} alt="Iicon" />
        {chart === "linechart" ? (
          <p className="area-analysis-footer-text">
            The chart tells about the number of coordinates of places,
            increased/decreased in the past months. It helps to get an
            estimation status of increasing/ decreasing deforestation.{" "}
          </p>
        ) : (
          <p className="area-analysis-footer-text">
            The pie chart tells about the causes of increase or decrease in
            number of coordinates in the past month of various causes like
            Farming, Build Up, and Lake which contribute to deforestation.{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default AreaAnalysis;
