import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ response }) => {
  // console.log("Pie chart response", response);
  const [causeFrequency, setCauseFrequency] = useState({});

  useEffect(() => {
    if (response.length > 0) {
      setCauseFrequency(getCauseFrequency("cause_name"));
    } else {
      setCauseFrequency({});
    }
  }, [response]);

  const getCauseFrequency = (key) => {
    return response.reduce((acc, item) => {
      const value = item[key];
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  };

  return <ApexPieChart causeFrequency={causeFrequency} />;
};

export default PieChart;

const ApexPieChart = ({ causeFrequency }) => {
  // console.log("causeFrequency", causeFrequency);
  const [series, setSeries] = useState([]);
  useEffect(() => {
    setSeries(Object.values(causeFrequency));
  }, [causeFrequency]);
  // console.log("series", series);

  const chartOptions = {
    chart: {
      width: 420,
      type: "donut",
    },
    labels: Object.keys(causeFrequency),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const renameLabel = (options, oldLabel, newLabel) => {
    const labels = options.labels.map((label) =>
      label === oldLabel ? newLabel : label
    );
    return { ...options, labels };
  };

  // Update the chartOptions
  const updatedChartOptions = renameLabel(chartOptions, "Building", "Build Up");

  return (
    <div>
      {series.length > 0 ? (
        <div
          id="chart"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2vh",
          }}
        >
          <ReactApexChart
            options={updatedChartOptions}
            series={series}
            type="donut"
            width={420}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2vh",
            fontSize: "18px",
          }}
        >
          No data present
        </div>
      )}
      <div id="html-dist"></div>
    </div>
  );
};
