import React, { useContext, useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { parseISO, format } from "date-fns";
import DashboardContext from "../../Context/DashboardContext";

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        style={{
          width: "2vh",
          height: "2vh",
          borderRadius: "0.5vh",
          border: "1px solid #4288F3",
          marginRight: "5px",
        }}
      />
      {label}
    </label>
  );
};

const ApexLineChart = ({ response, setSelectedCause }) => {

  const Dashboardapi = useContext(DashboardContext);
  
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [checked9, setChecked9] = useState(false);

  const [frequencyArray, setFrequencyArray] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  useEffect(() => {
    getFrequency();
  }, [response]);

  const sortAndGroupDates = (dates) => {
    const groupedDates = dates.reduce((acc, date) => {
      const parsedDate = new Date(date);
      const monthYear = `${
        parsedDate.getMonth() + 1
      }/${parsedDate.getFullYear()}`;

      if (!acc[monthYear]) {
        acc[monthYear] = date; // Use the first date as the representative date
      }

      return acc;
    }, {});

    const uniqueDatesArray = Object.values(groupedDates);
    const sortedDateArray = uniqueDatesArray.sort((a, b) => {
      const monthA = new Date(a).getMonth();
      const monthB = new Date(b).getMonth();
      return monthA - monthB;
    });
    return sortedDateArray;
  };
  const getFrequency = () => {
    const frequency = {};
    response.forEach((item) => {
      const date = parseISO(item.created_at);

      const month = format(date, "MMM"); // 'MMM' gives short form of month, e.g., 'Jan', 'Feb', etc.

      if (frequency[month]) {
        frequency[month] += 1;
      } else {
        frequency[month] = 1;
      }
    });

    //   const arrayDate = response.map(obj => {
    // 	const date = new Date(obj.created_at);
    // 	const year = date.getFullYear();
    // 	const month = String(date.getMonth() + 1).padStart(2, '0');
    // 	const day = String(date.getDate()).padStart(2, '0');
    // 	return `${month}/${day}/${year}`;
    //   });

    //   setDateArray(arrayDate);
    //   sortAndGroupDates(arrayDate);
    //   setDateArray(sortAndGroupDates(arrayDate));
    const monthOrder = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Convert the object to an array of [key, value] pairs
    const monthsArray = Object.entries(frequency);

    // Sort the array based on the order of the months
    monthsArray.sort((a, b) => {
      return monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]);
    });

    // Convert the sorted array back to an object
    const sortedMonthsObj = Object.fromEntries(monthsArray);

    // console.log(sortedMonthsObj);
    setFrequencyArray(sortedMonthsObj);

    const months = Object.keys(sortedMonthsObj);
    const dates = [
      "01/01/2000",
      "02/01/2000",
      "03/01/2000",
      "04/01/2000",
      "05/01/2000",
      "06/01/2000",
      "07/01/2000",
      "08/01/2000",
      "09/01/2000",
      "10/01/2000",
      "11/01/2000",
      "12/01/2000",
    ];
    const selectedIndices = months.map((month) =>
      monthToIndex(month, monthOrder)
    );
    const filtered = dates.filter((date) => {
      const monthIndex = parseInt(date.split("/")[0], 10) - 1;
      return selectedIndices.includes(monthIndex);
    });
    // console.log("filtered", filtered);
    setDateArray(filtered);
  };

  const monthToIndex = (monthStr, monthOrder) => {
    return monthOrder.indexOf(monthStr);
  };

  const handleChange = (value) => {
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setSelectedCause("");
    if (value === 1) {
      setSelectedCause("");
      setChecked1(!checked1);
    } else if (value === 2) {
      if (!checked2) setSelectedCause("Farming");
      setChecked2(!checked2);
    } else if (value === 3) {
      if (!checked3) setSelectedCause("Building");
      setChecked3(!checked3);
    } else {
      if (!checked4) setSelectedCause("Lake");
      setChecked4(!checked4);
    }
    getFrequency();
    // console.log("response :",response);
  };

  const handleGasChange = (value) =>{
    setChecked5(false);
    setChecked6(false);
    setChecked7(false);
    setChecked8(false);
    setChecked9(false);
    setSelectedCause("");
    if (value === 5) {
      if (!checked5) setSelectedCause("SO2");
      setChecked5(!checked5);
    } else if (value === 6) {
      if (!checked6) setSelectedCause("CO");
      setChecked6(!checked6);
    } else if (value === 7) {
      if (!checked7) setSelectedCause("CH4");
      setChecked7(!checked7);
    } else if(value === 8) {
      if (!checked8) setSelectedCause("NO2");
      setChecked8(!checked8);
    } else {
      if (!checked9) setSelectedCause("O3");
      setChecked9(!checked9);
    }
    getFrequency();
    // console.log("response :",response);
  }

  return (
    <>
      <div className="area-analysis-item">
        {Dashboardapi.type === "Deforestation" ? ( <ul>
          <li>
            <Checkbox
              label="Overall"
              value={checked1}
              onChange={() => handleChange(1)}
            />
          </li>
          <li>
            <Checkbox
              label="Farming"
              value={checked2}
              onChange={() => handleChange(2)}
            />
          </li>
          <li>
            <Checkbox
              label="Building"
              value={checked3}
              onChange={() => handleChange(3)}
            />
          </li>
          <li>
            <Checkbox
              label="Lake"
              value={checked4}
              onChange={() => handleChange(4)}
            />
          </li>
        </ul>
        ):(
           <ul>
           <li>
             <Checkbox
               label="SO2"
               value={checked5}
               onChange={() => handleGasChange(5)}
             />
           </li>
           <li>
             <Checkbox
               label="CO"
               value={checked6}
               onChange={() => handleGasChange(6)}
             />
           </li>
           <li>
             <Checkbox
               label="CH4"
               value={checked7}
               onChange={() => handleGasChange(7)}
             />
           </li>
           <li>
             <Checkbox
               label="NO2"
               value={checked8}
               onChange={() => handleGasChange(8)}
             />
           </li>
           <li>
             <Checkbox
               label="O3"
               value={checked9}
               onChange={() => handleGasChange(9)}
             />
           </li>
         </ul>
        )}
       
      </div>
      <div></div>
      <div>
        <LineChart dateArray={dateArray} frequencyArray={frequencyArray} />
      </div>
    </>
  );
};

const LineChart = ({ dateArray, frequencyArray }) => {
  // console.log("dateArray", dateArray.length);
  // toolbar: {
  //     tools: {
  //       download: false, // Disable the download icon
  //       selection: false, // Disable the selection icon
  //       zoom: false, // Disable the zoom icon
  //       zoomin: false, // Disable the zoom in icon
  //       zoomout: false, // Disable the zoom out icon
  //       pan: false, // Disable the pan icon
  //       reset: false // Disable the reset icon
  //     }
  //   }
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 200,
      width: 400,
      type: "line",
      // toolbar: {
      // 	show: false
      // }
      toolbar: {
        tools: {
          //   download: false, // Disable the download icon
          selection: false, // Disable the selection icon
          zoom: false, // Disable the zoom icon
          zoomin: false, // Disable the zoom in icon
          zoomout: false, // Disable the zoom out icon
          pan: false, // Disable the pan icon
          reset: false, // Disable the reset icon
        },
      },
    },
    forecastDataPoints: {
      count: 0,
    },
    stroke: {
      width: 5,
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [], // Initialize as an empty array
      tickAmount: 0,
      labels: {
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), "MMM");
        },
      },
    },
    title: {
      text: "No. of Coordinates",
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#FDD835"],
        type: "horizontal",
      },
    },
  });

  // console.log("frequencyArray", frequencyArray);
  // console.log("dateArray", dateArray);
  useEffect(() => {
    setSeries([
      {
        name: "Co-ordinates",
        data: Object.values(frequencyArray),
      },
    ]);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        tickAmount: dateArray.length,
        categories: dateArray,
      },
    }));
  }, [frequencyArray, dateArray]);

  console.log(series);

  return (
    <div>
      <div id="chart" style={{ margin: "1vh 0" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={280}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexLineChart;
